<template>
  <div class="contentDologin">
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="0"
      class="demo-ruleForm"
    >
      <div class="title">登录</div>
      <el-form-item label="" prop="phone">
        <el-input class="input" v-model="form.phone" placeholder="请输入账号" />
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          class="input"
          v-model="form.password"
          show-password
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-form-item label="" prop="code">
        <div class="yzmBox">
          <el-input
            class="input"
            v-model="form.code"
            maxlength="4"
            placeholder="请输入验证码"
          />
          <img @click="getCodeHandle" :src="captchaImg" />
        </div>
      </el-form-item>
      <div class="checkBox" @click="checkType = !checkType">
        <img v-if="checkType" src="@/assets/images/checkTrue.png" />
        <img v-else src="@/assets/images/checkFalse.png" />
        使用前需同意<span @click.stop="openDio(0)">《用户条款》</span>和<span
          @click.stop="openDio(1)"
          >《隐私声明》</span
        >
      </div>

      <el-button class="btn" @click="doLogin">立即登录</el-button>
      <div class="text">
        <div @click="forgetPwd">忘记密码？</div>
        <div @click="dialogVisible = true">加入我们</div>
      </div>
    </el-form>

    <div class="win-zhezhao" @click="closeJoinUs" v-if="dialogVisible">
      <div class="win-center" @click.stop="">
        <div class="win-title">加入我们，体验完整功能</div>
        <el-form
          :model="jform"
          :rules="jrules"
          ref="jruleForm"
          label-width="0"
          class="demo-ruleForm"
        >
          <div class="inputTit">公司名称</div>
          <el-form-item label="" prop="companyName">
            <el-input
              class="joinUsInput"
              v-model="jform.companyName"
              maxlength="50"
              placeholder="请输入"
            />
          </el-form-item>
          <div class="inputTit">联系人姓名</div>
          <el-form-item label="" prop="username">
            <el-input
              class="joinUsInput"
              v-model="jform.username"
              placeholder="请输入"
              maxlength="20"
            />
          </el-form-item>
          <div class="inputTit">联系人电话</div>
          <el-form-item label="" prop="phone">
            <el-input
              class="joinUsInput"
              v-model="jform.phone"
              placeholder="请输入"
              maxlength="20"
            />
          </el-form-item>
        </el-form>
        <div class="joinBtnBox">
          <el-button class="joinBtn" @click="doLoginJoin">立即加入</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuthCaptcha, authPassword, joinCommit } from "@/api/lx";
export default {
  name: "LoginDologin",
  data() {
    return {
      checkType: false,
      uuid: "", //生成图片的uuid
      dialogVisible: false,
      captchaImg: "",
      form: {
        phone: "", //手机号
        code: "", //验证码
        password: "", //密码
      },
      rules: {
        phone: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 20, message: "密码长度在6~20个数字", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      jform: {
        phone: "", //手机号
        username: "", //申请人姓名（限制长度20）
        companyName: "", //	公司名称（限制长度50）
      },
      jrules: {
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        username: [{ required: true, message: "请输入", trigger: "blur" }],
        companyName: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.createdUuid();
  },
  methods: {
    createdUuid() {
      var chars = "0123456789abcdef";
      var uuid = "";
      for (var i = 0; i < 32; i++) {
        var index = Math.floor(Math.random() * 16);
        uuid += chars[index];
      }
      console.log("222", uuid);
      this.uuid = uuid;
      this.getCodeHandle();
    },
    //获取图片验证码
    getCodeHandle() {
      getAuthCaptcha({ uuid: this.uuid }).then(async (res) => {
        // return;
        const blob = new Blob([res]); // 获取 Blob 对象
        const reader = new window.FileReader();

        reader.onloadend = () => {
          const data = reader.result;
          this.captchaImg = data;
          console.log("1231", this.captchaImg);
        };
        reader.readAsDataURL(blob);
      });
    },
    doLogin() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (!this.checkType) {
            this.$message.error("请阅读并勾选用户条款和隐私声明");
            return;
          }

          authPassword({
            ...this.form,
            uuid: this.uuid,
          }).then(async (res) => {
            // return;
            localStorage.setItem("token", res.data.token);
            this.$router.push({
              path: "/",
            });
          });
        }
      });
    },
    closeJoinUs() {
      this.$refs["jruleForm"].resetFields();
      this.jform = {
        phone: "", //手机号
        username: "", //申请人姓名（限制长度20）
        companyName: "", //	公司名称（限制长度50）
      };

      this.dialogVisible = false;
    },
    doLoginJoin() {
      this.$refs["jruleForm"].validate((valid) => {
        if (valid) {
          joinCommit({
            ...this.jform,
          }).then(async (res) => {
            this.$message.success("提交成功");
            this.dialogVisible = false;
          });
        }
      });
    },
    openDio(val) {
      this.$parent.open(val);
    },
    forgetPwd() {
      this.$parent.changeType(2);
    },
  },
};
</script>

<style lang="less" scoped>
.contentDologin {
  width: 100%;
  height: 100%;
  padding: 190px 101px 0 148px;
  box-sizing: border-box;
}
.title {
  box-sizing: border-box;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 40px;
  color: #00161a;
  margin-bottom: 5px;
}
.input {
  width: 380px;
  height: 50px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6edf0;
  margin-top: 25px;
  font-size: 16px;
  /deep/ .el-input__inner {
    width: 100%;
    height: 100%;
  }
}
.yzmBox {
  width: 380px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .input {
    width: 231px;
    height: 50px;
    margin-top: 0;
  }
  img {
    width: 139px;
    height: 50px;
    border-radius: 8px;
    display: block;
  }
}
.checkBox {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #525f66;
  margin-top: 37px;
  cursor: pointer;
  span {
    color: #00d8c3;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
.btn {
  width: 100%;
  height: 59px;
  background: #00d8c3;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 57px;
  border: 1px solid #00d8c3;
  &.is-active,
  &:active {
    border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
    outline: none; /* 移除默认的轮廓线 */
  }
}
.text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  color: #00d8c3;
  line-height: 20px;
  margin-top: 20px;
  cursor: pointer;
}
/deep/.el-form-item {
  margin: 0;
}
.joinUsInput {
  width: 100%;
}
.win-zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.win-center {
  width: 549px;
  height: 442px;
  background: #ffffff;
  padding: 24px 50px;
  box-sizing: border-box;
  .win-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #00161a;
    line-height: 28px;
    margin-bottom: 7px;
  }
  .inputTit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #00161a;
    line-height: 22px;
    margin-top: 18px;
  }
  .joinUsInput {
    width: 460px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e6edf0;
    margin-top: 10px;
  }
  .joinBtnBox {
    width: 460px;
    display: flex;
    flex-direction: row-reverse;
  }
  .joinBtn {
    width: 120px;
    height: 40px;
    background: #00d8c3;
    font-family: PingFangSC, PingFang SC;
    font-size: 14px;
    color: #ffffff;
    border: 1px solid #00d8c3;
    margin-top: 20px;
    &.is-active,
    &:active {
      border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
      outline: none; /* 移除默认的轮廓线 */
    }
  }
}
</style>